<template>
  <div class="card-group mt-4 mb-7">
    <div
      class="card"
      v-for="(option, index) in layouts.settings.options"
      :key="index"
    >

      <div class="transform-box">
        <div class="card-title ml-1"><span>COUPON</span></div>
        <div class="coupon-number px-1">{{ layouts.data.couponCode }}</div>
      </div>

      <CouponSVG primary-color="white" :active="true" />
      <div class="right-card">
        <div class="percentage">
          <div class="percentage-value">
            {{ parseCouponRate(option.value) }}
          </div>
          <div class="percentage-unit pl-2">
            <span class="f-s-30">
              {{ dataSources.couponType === 1 ? "%" : "$" }}
            </span>
            <div class="f-s-16 mt-n2">OFF</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Tool } from '@/assets/js/util'

import CouponSVG from './CouponSVG.vue'

export default {
  components: {
    CouponSVG
  },
  data () {
    return {
      assists: {
        tool: new Tool()
      },
      dataSources: {
        // type为1 对应百分比， type为2  对应为$
        couponType: 1
      },
      layouts: {
        data: {
          checkIndex: 0,
          couponCode: '',
          percentage: null
        },
        settings: {
          options: [
            {
              value: null
            }
          ]
        }
      }
    }
  },
  watch: {
    '$store.getters.getCouponDetails': {
      handler (val) {
        this.getCouponDetails()
      }
    }
  },
  mounted () {
    this.getCouponDetails()
  },
  methods: {
    parseCouponRate (val) {
      if (this.assists.tool.isNotEmpty(val) && this.dataSources.couponType === 1) {
        return (-Number(val) * 100).toFixed(val.slice(5, 7) ? 2 : 0)
      } else {
        return -Number(val)
      }
    },
    getCouponDetails () {
      const res = this.$store.getters.getCouponDetails
      const couponCode = this.$store.getters.getCouponCode
      this.$emit('couponDetails', res)
      this.layouts.data.couponCode = couponCode || 'N/A'
      this.dataSources.couponType = res[0].type
      this.layouts.settings.options[0].value = res[0].value
    }
  }
}
</script>

<style lang="sass" scoped>
.card-group
  display: flex
  align-items: center
  .card
    width: 350px
    position: relative
    & svg
      width: 100%
      display: block

    .transform-box
      position: absolute
      top: 35%
      left: -4%
      transform: rotate(-90deg)
      transform-origin: center center
      z-index: 1

    &-title
      font-size: 16px
      font-weight: 900
      letter-spacing: 6px
      text-align: center

    .coupon-number
      font-size: 10px
      font-weight: 600
      color: var(--theme_primary)
      border: 1px solid var(--theme_primary)
      border-radius: 2px
      letter-spacing: 3px

    .right-card
      position: absolute
      top: 0
      right: 0
      width: 268px
      height: 100%
      .percentage
        display: flex
        justify-content: center
        width: 100%
        height: 100%
        font-weight: 900
        &-value
          margin: auto 0
          font-size: 64px
          color: white
          font-weight: bold
        &-unit
          margin: auto 0
          color: #ffffff
          font-weight: 900
  .card + .card
    margin-left: 18px
</style>
