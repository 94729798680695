<template>
  <svg class="svg-shadow" viewBox="0 0 327 130" fill="none" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop offset="0%" class="color-primary"/>
        <stop offset="50%" class="color-third"/>
        <stop offset="100%" class="color-secondary"/>
      </linearGradient>
    </defs>
    <path d="M76 14V8C71.5817 8 68 4.41828 68 0H8C8 4.41828 4.41828 8 0 8V122C4.41828 122 8 125.582 8 130H68C68 125.582 71.5817 122 76 122V116C74.3431 116 73 114.657 73 113C73 111.343 74.3431 110 76 110V104C74.3431 104 73 102.657 73 101C73 99.3431 74.3431 98 76 98V92C74.3431 92 73 90.6569 73 89C73 87.3431 74.3431 86 76 86V80C74.3431 80 73 78.6569 73 77C73 75.3431 74.3431 74 76 74V68C74.3431 68 73 66.6569 73 65C73 63.3431 74.3431 62 76 62V56C74.3431 56 73 54.6569 73 53C73 51.3431 74.3431 50 76 50V44C74.3431 44 73 42.6569 73 41C73 39.3431 74.3431 38 76 38V32C74.3431 32 73 30.6569 73 29C73 27.3431 74.3431 26 76 26V20C74.3431 20 73 18.6569 73 17C73 15.3431 74.3431 14 76 14Z" fill="white" />
    <path d="M319 130H84C84 125.582 80.4183 122 76 122L76 116C77.6569 116 79 114.657 79 113C79 111.343 77.6569 110 76 110L76 104C77.6569 104 79 102.657 79 101C79 99.3431 77.6569 98 76 98L76 92C77.6569 92 79 90.6569 79 89C79 87.3431 77.6569 86 76 86L76 80C77.6569 80 79 78.6569 79 77C79 75.3431 77.6569 74 76 74L76 68C77.6569 68 79 66.6569 79 65C79 63.3431 77.6569 62 76 62L76 56C77.6569 56 79 54.6569 79 53C79 51.3431 77.6569 50 76 50L76 44C77.6569 44 79 42.6569 79 41C79 39.3431 77.6569 38 76 38L76 32C77.6569 32 79 30.6569 79 29C79 27.3431 77.6569 26 76 26L76 20C77.6569 20 79 18.6569 79 17C79 15.3431 77.6569 14 76 14L76 8C80.4183 8 84 4.41828 84 9.97323e-06L319 0C319 4.41828 322.582 8 327 8L327 122C322.582 122 319 125.582 319 130Z" :fill="active ? 'url(#gradient)' : 'white'"/>
    <path d="M110 13L111.317 15.1878L113.804 15.7639L112.13 17.6922L112.351 20.2361L110 19.24L107.649 20.2361L107.87 17.6922L106.196 15.7639L108.683 15.1878L110 13Z" :fill="primaryColor"/>
    <path d="M251 13L252.317 15.1878L254.804 15.7639L253.13 17.6922L253.351 20.2361L251 19.24L248.649 20.2361L248.87 17.6922L247.196 15.7639L249.683 15.1878L251 13Z" :fill="primaryColor"/>
    <path d="M251 109L252.317 111.188L254.804 111.764L253.13 113.692L253.351 116.236L251 115.24L248.649 116.236L248.87 113.692L247.196 111.764L249.683 111.188L251 109Z" :fill="primaryColor"/>
    <path d="M231 109L232.317 111.188L234.804 111.764L233.13 113.692L233.351 116.236L231 115.24L228.649 116.236L228.87 113.692L227.196 111.764L229.683 111.188L231 109Z" :fill="primaryColor"/>
    <path d="M110 109L111.317 111.188L113.804 111.764L112.13 113.692L112.351 116.236L110 115.24L107.649 116.236L107.87 113.692L106.196 111.764L108.683 111.188L110 109Z" :fill="primaryColor"/>
    <path d="M170 109L171.317 111.188L173.804 111.764L172.13 113.692L172.351 116.236L170 115.24L167.649 116.236L167.87 113.692L166.196 111.764L168.683 111.188L170 109Z" :fill="primaryColor"/>
    <path d="M130 13L131.317 15.1878L133.804 15.7639L132.13 17.6922L132.351 20.2361L130 19.24L127.649 20.2361L127.87 17.6922L126.196 15.7639L128.683 15.1878L130 13Z" :fill="primaryColor"/>
    <path d="M271 13L272.317 15.1878L274.804 15.7639L273.13 17.6922L273.351 20.2361L271 19.24L268.649 20.2361L268.87 17.6922L267.196 15.7639L269.683 15.1878L271 13Z" :fill="primaryColor"/>
    <path d="M271 109L272.317 111.188L274.804 111.764L273.13 113.692L273.351 116.236L271 115.24L268.649 116.236L268.87 113.692L267.196 111.764L269.683 111.188L271 109Z" :fill="primaryColor"/>
    <path d="M130 109L131.317 111.188L133.804 111.764L132.13 113.692L132.351 116.236L130 115.24L127.649 116.236L127.87 113.692L126.196 111.764L128.683 111.188L130 109Z" :fill="primaryColor"/>
    <path d="M190 109L191.317 111.188L193.804 111.764L192.13 113.692L192.351 116.236L190 115.24L187.649 116.236L187.87 113.692L186.196 111.764L188.683 111.188L190 109Z" :fill="primaryColor"/>
    <path d="M150 13L151.317 15.1878L153.804 15.7639L152.13 17.6922L152.351 20.2361L150 19.24L147.649 20.2361L147.87 17.6922L146.196 15.7639L148.683 15.1878L150 13Z" :fill="primaryColor"/>
    <path d="M291 13L292.317 15.1878L294.804 15.7639L293.13 17.6922L293.351 20.2361L291 19.24L288.649 20.2361L288.87 17.6922L287.196 15.7639L289.683 15.1878L291 13Z" :fill="primaryColor"/>
    <path d="M291 109L292.317 111.188L294.804 111.764L293.13 113.692L293.351 116.236L291 115.24L288.649 116.236L288.87 113.692L287.196 111.764L289.683 111.188L291 109Z" :fill="primaryColor"/>
    <path d="M150 109L151.317 111.188L153.804 111.764L152.13 113.692L152.351 116.236L150 115.24L147.649 116.236L147.87 113.692L146.196 111.764L148.683 111.188L150 109Z" :fill="primaryColor"/>
    <path d="M210 109L211.317 111.188L213.804 111.764L212.13 113.692L212.351 116.236L210 115.24L207.649 116.236L207.87 113.692L206.196 111.764L208.683 111.188L210 109Z" :fill="primaryColor"/>
    <path d="M172.272 12.2H180.126V13.684H177.018V22H175.366V13.684H172.272V12.2ZM194.836 22L193.898 19.788H188.956L188.018 22H186.296L190.622 12.2H192.316L196.6 22H194.836ZM189.572 18.318H193.268L191.42 13.964L189.572 18.318ZM213.432 22H211.444L208.224 17.674L206.558 19.508V22H204.906V12.2H206.558V17.268L211.234 12.2H213.18L209.386 16.386L213.432 22ZM221.955 12.2H228.955V13.684H223.607V16.344H228.395V17.814H223.607V20.516H229.123V22H221.955V12.2Z" :fill="primaryColor"/>
  </svg>
</template>

<script>
export default {
  name: 'CouponSVG',
  props: {
    primaryColor: {
      type: String,
      default: window.document.body.style.getPropertyValue('--theme_primary')
    },
    active: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="sass" scoped>
.color-primary
  stop-color: var(--theme_primary)
.color-secondary
  stop-color: var(--theme_secondary)
.color-third
  stop-color: var(--theme_third)

svg
  // transform: scale(1.2)

.svg-shadow
  filter: drop-shadow(0 10px 10px rgba(0, 0, 0, 0.15))
</style>
