<template>
  <div class="width-500 d-flex flex-column justify-center align-center mx-auto">
    <div class="width-full f-s-20 f-w-600 mb-4">
      <span>Hi, {{layouts.data.firstName}}</span>
    </div>
    <v-card class="load-card elevation-1">
      <v-row no-gutters class="height-full">
        <v-col
          v-for="(item, index) in layouts.settings.headPanel"
          :key="index"
          cols="6"
          class="d-flex flex-column justify-center align-center card-item"
        >
          <div class="card-text">{{ item.title }}</div>
          <div class="card-value">{{ layouts.data.headerValue[item.fieldKey] }}</div>
        </v-col>
      </v-row>
    </v-card>

    <div class="f-s-14 f-w-600 mt-4">
      Congratulations, your are qualified for a discount on your loan balance.
    </div>

    <CouponCardGroup @couponDetails="getCompareTransactionFee"/>

    <v-card class="amount-card elevation-0">
      <v-simple-table class="amount-table" dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th></th>
              <th>Current</th>
              <th>Revised</th>
            </tr>
          </thead>
          <tbody>
            <tr
              :class="{ 'item-total': item.name === 'Total' }"
              v-for="(item, index) in paymentFeeSetting"
              :key="item.name"
            >
              <td class="name-td" :style="tableBorderDisplay(index)">
                {{ item.name }}
              </td>
              <td class="current-td" :style="tableBorderDisplay(index)" :class="item.original !== item.current ? 'line-through' : ''">
                {{ assists.currency.format(item.original) }}
              </td>
              <td :style="tableBorderDisplay(index)">
                {{ assists.currency.format(item.current) }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>

    <div class="d-flex width-500 mt-10">
      <v-spacer></v-spacer>
      <web-btn
        class="mr-5"
        :height="50"
        width="30%"
        @click="clickContinue"
      >
        Continue
      </web-btn>
    </div>
  </div>
</template>

<script>
import { OnlineApi } from '@/api'
import { Tool, Currency } from '@/assets/js/util'
import WebBtn from '@/components/base/WebBtn'
import CouponCardGroup from './childComps/CouponCardGroup.vue'

export default {
  components: {
    CouponCardGroup,
    WebBtn
  },
  data () {
    return {
      assists: {
        tool: new Tool(),
        currency: new Currency()
      },
      layouts: {
        data: {
          loanNo: 'XXX-XXX-XXXX',
          originationDate: 'N/A',
          firstName: '',
          list: [
            {
              name: 'Principal',
              fieldKey: 'principal',
              current: 0,
              revised: 0
            },
            {
              name: 'Finance Fee',
              fieldKey: 'financeFee',
              current: 0,
              revised: 0
            },
            {
              name: 'Unpaid Fee',
              fieldKey: 'unpaidFee',
              current: 0,
              revised: 0
            },
            {
              name: 'Total',
              fieldKey: 'total',
              current: 0,
              revised: 0
            }
          ],
          checkIndex: 0,
          headerValue: {
            loanNo: '',
            bankName: '',
            loanAmount: '',
            routingNo: '',
            effectiveDate: '',
            accountNo: ''
          }
        },
        settings: {
          headPanel: [
            {
              title: 'Loan #',
              fieldKey: 'loanNo'
            },
            {
              title: 'Bank Name',
              fieldKey: 'bankName'
            },
            {
              title: 'Loan Amount',
              fieldKey: 'loanAmount'
            },
            {
              title: 'Routing No',
              fieldKey: 'routingNo'
            },
            {
              title: 'Effective Date',
              fieldKey: 'effectiveDate'
            },
            {
              title: 'Account No',
              fieldKey: 'accountNo'
            }
          ]
        }
      }
    }
  },
  computed: {
    paymentFeeSetting () {
      const init = [...this.layouts.data.list]
      const result = []
      init.map((item) => {
        if (item.original !== 0) {
          result.push(item)
        }
      })
      this.$store.commit('setTransactionFeeList', [...result])
      return result
    }
  },
  watch: {
    '$store.getters.getCollectionLoanNo': {
      handler (val) {
        this.layouts.data.loanNo = val
        this.getPaymentOption()
        this.getPersonalInfo()
      }
    }
  },
  mounted () {
    this.layouts.data.loanNo = this.$store.getters.getCollectionLoanNo

    if (this.assists.tool.isNotEmpty(this.layouts.data.loanNo)) {
      this.getPaymentOption()
      this.getPersonalInfo()
    }
  },
  methods: {
    getPersonalInfo () {
      OnlineApi.getPaymentPersonalInfo({ loanNo: this.layouts.data.loanNo },
        (res) => {
          this.layouts.data.firstName = res.firstName
        })
    },
    getPaymentOption () {
      OnlineApi.getPaymentOption(
        { loanNo: this.layouts.data.loanNo },
        (res) => {
          if (this.assists.tool.isNotEmpty(res)) {
            res.routingNo = '*****' + res.routingNo.substring(res.routingNo.length - 4)
            res.accountNo = '******' + res.accountNo.substring(res.accountNo.length - 4)
            res.loanAmount = this.assists.currency.format(res.loanAmount)
            Object.assign(this.layouts.data.headerValue, res)
          }
        },
        (err) => {
          this.$store.commit('setPopupInformation', {
            message: err.message
          })
        }
      )
    },
    getCompareTransactionFee (data) {
      const input = {
        loanNo: this.$store.getters.getCollectionLoanNo,
        // type为1 对应百分比， type为2  对应为$
        couponDetails: data
      }
      OnlineApi.compareTransactionFee(
        input,
        (res) => {
          if (this.assists.tool.isNotEmpty(res)) {
            Object.keys(res).forEach((item) => {
              this.layouts.data.list.map((list) => {
                if (list.fieldKey === item) {
                  list = Object.assign(list, res[item])
                }
              })
            })
          }
        },
        (err) => {
          this.$store.commit('setPopupInformation', {
            message: err.message
          })
        }
      )
    },
    tableBorderDisplay (index) {
      return index !== this.paymentFeeSetting.length - 2 ? 'border: none' : ''
    },
    clickContinue () {
      this.$router.push('/coupon/schedule')
    }
  }
}
</script>

<style lang='sass' scoped>
.load-card
  width: 500px
  padding-bottom: 10px
  border-radius: 8px
  & .card-item
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    margin-top: 10px
    & .card-text
      color: #9CA0AB
      font-size: 12px
    & .card-value
      color: #000410
      font-size: 16px
      font-weight: bold
      width: 100%
      text-align: center
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis  /* 超出文本展示为省略号 */
.amount-card
  width: 350px
  border-radius: 8px
  & .amount-table
    padding: 4px 8px 8px 8px
    background-color: #ededee
    & th
      height: 30px
      text-align: right !important
      font-weight: 500
      color: black !important
      font-size: 10px
    & td
      height: 24px
      text-align: right
      font-size: 12px
      font-weight: 500
    & .name-td
      text-align: left
      font-weight: 500
      color: #464C5B
    & .current-td
      color: #9CA0ABFF
    & .line-through
      text-decoration: line-through
    & .item-total
      & :nth-child(1)
        padding-top: 4px
        font-size: 16px
        color: #000410
      & :nth-child(2)
        padding-top: 4px
        font-size: 16px
      & :nth-child(3)
        padding-top: 4px
        font-size: 16px
        color: var(--theme_primary)
</style>
